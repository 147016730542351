<template>
  <div class="containerLayout">
    <div class="notFooterClub">
      <Header />
      <section class="section">
        <div class="container">
          <slot />
        </div>
      </section>
    </div>
    <Footer />
  </div>
</template>

<script>
// - components
// - mixins
// - props
// - data
// - computed
// - watch
// - lifecycle
// - methods
// - template
export default {
  props: {
    useFullHeight: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.containerLayout {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}
.notFooterClub {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
section {
  padding: 1.5rem 1.5rem;
}
</style>
